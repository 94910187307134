<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';

import sendIcon from '@i/plane-white.svg';

const route = inject('route');

const form = useForm({});

const props = defineProps({
    showing: Boolean,
});

const emits = defineEmits(['update:showing']);
const closeForm = () => emits('update:showing', false);
const notify = () => {
    form.get(route('staff.management.send-salary-notification'), {});
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm" class="sm:max-w-[800px]">
        <template #default>
            <div class="text-[14px] mt-4">
                {{ $t('Are you sure you want to do this? This action cannot be undone.') }}
            </div>
            <div class="flex justify-end">
                <Button orange type="button" :text="$t('Send')" :icon="sendIcon" @click="notify" />
            </div>
        </template>
    </Modal>
</template>
